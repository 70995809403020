<app-confetti [showConfetti]="showConfetti.asObservable()"></app-confetti>
<app-confetti [showConfetti]="showConfetti.asObservable()"></app-confetti>
<app-confetti [showConfetti]="showConfetti.asObservable()"></app-confetti>
<div class="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
  <div class="lottie-container" style="width: 17em">
    <ng-lottie [options]="{ path: './assets/lotties/success.json', loop: false }"></ng-lottie>
  </div>
  <h1 class="fut-h1 mt-4">{{ data.title }}</h1>
  <span [innerHTML]="data.message" class="fut-span text-center"></span>
  <h3 class="fut-h3 text-center my-3 fut-bold">{{ data.submessage | mask: '+00 000 000 0000' }}</h3>
  @if (data.button) {
    <button class="fut-btn fut-btn-primary" mat-dialog-close>{{ data.button }}</button>
  }
</div>
