import { MAT_DIALOG_DATA, MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LottieComponent } from 'ngx-lottie';
import { NgxMaskModule } from 'ngx-mask';
import { ConfettiComponent } from '../../components/confetti/confetti.component';

export interface SuccessDialogData {
  title: string;
  message: string;
  submessage: string;

  show_confetti?: boolean;
  button?: string;

  auto_close?: boolean;
}

@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss'],
  standalone: true,
  imports: [LottieComponent, MatDialogClose, NgxMaskModule, ConfettiComponent],
})
export class SuccessDialogComponent implements OnInit {
  showConfetti = new Subject<undefined>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SuccessDialogData,
    private dialogRef: MatDialogRef<SuccessDialogComponent>
  ) {}

  ngOnInit(): void {
    if (this.data.show_confetti) this.showConfetti.next(undefined);

    if (this.data.auto_close) {
      setTimeout(() => {
        this.dialogRef.close();
      }, 4500);
    }
  }
}
