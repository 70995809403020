import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';

import confetti from 'canvas-confetti';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-confetti',
  templateUrl: './confetti.component.html',
  styleUrls: ['./confetti.component.scss'],
  standalone: true,
})
export class ConfettiComponent implements OnInit, OnDestroy {
  @Input() showConfetti: Observable<undefined> = new Observable();

  end = Date.now() + 15 * 1000;

  _showConfetti!: any;

  constructor(
    private renderer2: Renderer2,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this._showConfetti = this.showConfetti.subscribe(() => this.show());
  }

  show(): void {
    const colors = ['#389CE1', '#E3EA15'];
    const canvas = this.renderer2.createElement('canvas');

    this.renderer2.appendChild(this.elementRef.nativeElement, canvas);

    const obj_confetti = confetti.create(canvas, {
      resize: true,
    });

    obj_confetti({
      particleCount: this.randomNumber(150, 400),
      angle: 60,
      spread: this.randomNumber(150, 350),
      origin: { x: 0 },
      colors: colors,
    });
    obj_confetti({
      particleCount: this.randomNumber(150, 400),
      angle: 120,
      spread: this.randomNumber(150, 350),
      origin: { x: 1 },
      colors: colors,
    });
  }

  randomNumber(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  frame() {
    if (Date.now() < this.end) {
      requestAnimationFrame(this.frame);
    }
  }

  ngOnDestroy() {
    this._showConfetti.unsubscribe();
  }
}
